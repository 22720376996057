import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {push} from 'react-router-redux';
import {
	Row, 
	Col,
	Card
} from 'antd';
import {processList} from './constants';

const { Meta } = Card;

class Processes extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const {goToPage} = this.props;

		const processCards = processList.map((Pros) => {
			return (
				<Col 
					className='type' 
					xs={24} sm={12} md={8} lg={6} xl={6} 
					key={Pros.name}  
					onClick={() => goToPage(`portfolio#${Pros.type}`)}
				>
					<Card
						hoverable
						cover={<img alt="example" src={Pros.url}/>}
					>
					<Meta
						title={<span className="name">{Pros.name}</span>}
					/>
					</Card>
				</Col>
				);
		});

		return (
			<Row type="flex" justify="space-around" align="middle" className='process'>
				{processCards}
			</Row>
		);
	}
}

Processes.propTypes = {
	goToPage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToPage: (page) => dispatch(push(page))
	};
};

export default connect(null, mapDispatchToProps)(Processes);