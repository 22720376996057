import React, { Component } from 'react';
import {Carousel, Row, Col, Icon} from 'antd';

class FullCarousel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			height: window.innerHeight
		};
		window.addEventListener("resize", this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({
			height: window.innerHeight
		});

	};

	render() {
		return (
			<Row className="carousel" >
				<Col span={24} style={{maxHeight: this.state.height}}>
					<Carousel dots={false} autoplay>
						<Col span={24}>
							<div className="overlayBlack"  style={{height: this.state.height}}></div>
							<div className="overlayTeal"  style={{height: this.state.height}}></div>
							<img src="/images/slide1.jpg" alt="" />
						</Col>
						<Col span={24}>
							<div className="overlayBlack"  style={{height: this.state.height}}></div>
							<div className="overlayTeal"  style={{height: this.state.height}}></div>
							<img src="/images/slide2.jpg" alt=""/>
						</Col>
						<Col span={24}>
							<div className="overlayBlack"  style={{height: this.state.height}}></div>
							<div className="overlayTeal"  style={{height: this.state.height}}></div>
							<img src="/images/slide3.jpg" alt=""/>
						</Col>
						<Col span={24}>
							<div className="overlayBlack"  style={{height: this.state.height}}></div>
							<div className="overlayTeal"  style={{height: this.state.height}}></div>
							<img src="/images/slide4.jpg" alt=""/>
						</Col>
					</Carousel>
					<div className='paperleaf'>
						<Row span={24} className="logo" type="flex" justify="center" align="middle">
							<Col span={12}>
								<img className="leaf" src="/images/paper.svg" alt="" />
							</Col>
						</Row>
					</div>
					<div className="mote">
							<h3>Bringing your creative ideas to life!</h3>
					</div>
					<a href="#headding">
						<Icon type="caret-down" className='down'/>
					</a>
				</Col>
			</Row>
		);
	}
}

export default FullCarousel;