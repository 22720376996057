import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu';

import {Layout} from 'antd';
const {Content} = Layout;

class Main extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<Layout>
				<Menu />
				<Content className="content">
					{this.props.children}
				</Content>
			</Layout>
		);
	}
}

Main.propTypes = {
	children: PropTypes.object.isRequired
};

export default Main;






