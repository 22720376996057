import React, { Component } from 'react';
import Gallery from './Gallery';
import {
	Row
} from 'antd';
import Banner from '../layouts/Banner';

class Slider extends Component {
	constructor(props) {
		super(props);
		window.scrollTo(0,0);
	}

	render() {
		return (
			<Row type="flex" justify="center" align="middle" className="slider">
				<Banner img="/images/portfolio.jpg" title="Portfolio"/>
				<Gallery/>
			</Row>
		);
	}
}

export default Slider;