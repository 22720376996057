import React, { Component } from 'react';
import ContactForm from './ContactForm';
import Address from './Address';
import Map from './Map';
import {
	Row,
	Col
} from 'antd';
import Banner from '../layouts/Banner';

class About extends Component {
	constructor(props) {
		super(props);
		window.scrollTo(0,0);
	}

	render() {

		return (
			<Row type="flex" justify="center" align="middle" className="contact view">
				<Banner img="/images/contact.jpg" title="Contact"/>
				<Col span={24} className="desclamer1">
					Let us know how we can bring your creative printing needs to life.
				</Col>
				<Col span={24} className="desclamer2">
					We love hearing from you!
				</Col>
				<Col span={24}>
					<Row  type="flex" justify="space-around" align="middle" className="contactUs">
						<Col xs={24} sm={24} md={24} lg={10} xl={10} className="section">
							<ContactForm />
						</Col>
						<Col xs={24} sm={24} md={24} lg={10} xl={10} className="section">
							<Map />
						</Col>
					</Row>
				</Col>
				<Col  span={24} sm={24} md={24} lg={22} xl={16} className="address">
					<Address />
				</Col>
			</Row>
		);
	}
}

export default About;