
export const processList = [
	{
		name: "Letterpress",
		url: "/images/letterPress.jpg",
		type: "letterpress",
		style: {
			width: '250%',
			right: '-50%'
		}
	},
	{
		name: "Foil Stamping",
		url: "/images/FoilStamping.jpg",
		type: "foil",
		style: {
			width: '250%',
			right: '-50%'
		}
	},
	{
		name: "Offset / Digital Printing",
		url: "/images/FlatPrint.jpg",
		type: "offset",
		style: {
			width: '250%'
		}
	},
	{
		name: "Specialties",
		url: "/images/speciality.jpg",
		type: "specialty",
		style: {
			width: '250%',
			right: '-75%'
		}
	}
];