import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Radio,
	Col
} from 'antd';
import constants from '../Portfolio/constants';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

const types = {
			foil: "Foil Stamping",
			letterpress: "Letterpress",
			offset: "Offset / Digital Printing",
			specialty: "Specialty"
		};

class Gallery extends Component {
	constructor(props) {
		super(props);
		window.scrollTo(0,0);

		this.imageGallery = undefined;

		let processess = Object.keys(types);
		let filter = props.filter;
		filter = filter.substr(1,filter.length);
		filter = (processess.indexOf(filter) >= 0 ) ? filter : "";

		this.state = {
			gallery: [...constants.portfolio].filter((val) => {
				if(filter.length == 0)
					return true;
				return val.type == filter;
			}),
			process: filter
		};
	}

	onChange = (e) => {
		this.setState({
			gallery: [...constants.portfolio].filter((val) => {
				if(e.target.value.length == 0)
					return true;
				return val.type == e.target.value;
			})
		});
		this.imageGallery.slideToIndex(0);
	};

	render() {
		const images = this.state.gallery.map((img) => {
			return {
				original: `/images/portfolio/${img.url}`,
				thumbnail: `/images/portfolio/${img.url}`
			}
		})
		return (
			<Col span={24}>
				<Col span={24} className="ProcessFilter">
					<Radio.Group 
						defaultValue={this.state.process} 
						buttonStyle="solid" 
						size="large" 
						onChange={this.onChange}
					>
						<Radio.Button value="">All</Radio.Button>
						<Radio.Button value="foil">Foil Stamping</Radio.Button>
						<Radio.Button value="letterpress">Letterpress</Radio.Button>
						<Radio.Button value="offset">Offset / Digital Printing</Radio.Button>
						<Radio.Button value="specialty">Specialties</Radio.Button>
					</Radio.Group>
				</Col>
				<Col span={24} className="imageGallery">
					<ImageGallery 
						ref={i => this.imageGallery = i}
						items={images}
						lazyLoad={true}
						showIndex={true}
						slideDuration={200}
						stopPropagation={true}
						showPlayButton={false}
					/>
				</Col>
			</Col>
		);
	}
}

Gallery.propTypes = {
	filter: PropTypes.string
};

const mapStateToProps = (state) => {
	return {
		filter: state.routing.locationBeforeTransitions.hash
	};
};

export default connect(mapStateToProps)(Gallery);

// window.innerHeight

/*

		const images = this.state.gallery.map((img, index) => {
			return (
				<div key={index}>
                    <img src={`/images/portfolio/${img.url}`} />
                </div>
			);
		})
					<Carousel 
						showArrows={true}
						showIndicators={false}
						dynamicHeight={true}
						centerMode={true}
						width="70%"
						infiniteLoop={true}
						showThumbs={true}
					>
						{images}
					</Carousel>
*/











