import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Form,
	Input,
	Col,
	Button,
	message
} from 'antd';
import axios from 'axios';

const FormItem = Form.Item;
const { TextArea } = Input;

class ContactForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFields((err, values) => {
			if (!err) {
				let mail = {
					email: values.email,
					subject: values.subject,
					surname: values.surname,
					first: values.first,
					message: values.message
				}
				
				this.setState({
					loading: true
				});
				axios.post(`/Correos/Send`, mail)
				.then( () => {
					message.success("We received your email we'll be in contact as soon as possible.");

				})
				.catch( () => {
					message.error('Something went wrong, please try again later.');
				})
				.then(() => {
					this.setState({
						loading: false
					});
				});
			}
		});
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<Form className="form">
				<Col span={12}>
					<FormItem
						label="First Name"
					>
						{
							getFieldDecorator('first', {
								rules: [{
									required: true, 
									message: 'Please input your First Name!',
								}]
							})(
								<Input />
							)
						}
					</FormItem>
				</Col>
				<Col span={12}>
					<FormItem
						label="Surname"
					>
						{
							getFieldDecorator('surname', {
								rules: [{
									required: true, 
									message: 'Please input your Surname!',
								}]
							})(
								<Input />
							)
						}
					</FormItem>
				</Col>
				<Col span={24}>
					<FormItem
						label="E-mail"
					>
						{
							getFieldDecorator('email', {
								rules: [{
									type: 'email', 
									message: 'The input is not valid E-mail!',
								}, {
									required: true, 
									message: 'Please input your E-mail!',
								}]
							})(
								<Input />
							)
						}
					</FormItem>
				</Col>
				<Col span={24}>
					<FormItem
						label="Subject"
					>
						{
							getFieldDecorator('subject', {
								rules: [{
									required: true, 
									message: 'Please input a Subject!',
								}]
							})(
								<Input />
							)
						}
					</FormItem>
				</Col>
				<Col span={24}>
					<FormItem
						label="Message"
					>
						{
							getFieldDecorator('message', {
								rules: [{
									required: true, 
									message: 'Please input a message!',
								}]
							})(
								<TextArea rows={4} />
							)
						}
					</FormItem>
				</Col>
				<Col span={24} className="submit">
					<Button type="primary" onClick={this.handleSubmit} loading={this.state.loading}>
						<span className="icon-paper-plane-o" /> Send
					</Button>
				</Col>

			</Form>
		);
	}
}

ContactForm.propTypes = {
	form: PropTypes.object
};

export default (Form.create()(ContactForm));











