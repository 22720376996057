import React, { Component } from 'react';
import Disclamer from './Disclamer';
import {
	Row
} from 'antd';
import Banner from '../layouts/Banner';

class About extends Component {
	constructor(props) {
		super(props);
		window.scrollTo(0,0);
	}
	render() {
		return (
			<Row type="flex" justify="center" align="middle" className="about view">
				<Banner img="/images/about.jpg" title="About"/>
				<Disclamer />
			</Row>
		);
	}
}

export default About;