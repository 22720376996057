import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Popover,
	List
} from 'antd';
import {push} from 'react-router-redux';

class Menu extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {goToPage} = this.props;
		const menu = (
			<List
				className="menu-dropdown"
				dataSource={['', 'portfolio', 'contact', 'about']}
				renderItem={item => (<List.Item onClick={() => goToPage(`/${item}`)}>{`${(item.length>0) ? item : 'Home'}`}</List.Item>)}
			/>
		);
		return (
			<Popover placement="bottomLeft" content={menu} trigger="hover">
				<span className="icon-menu menu-burger"></span>
			</Popover>
		);
	}
}

Menu.propTypes = {
	goToPage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToPage: (page) => dispatch(push(page))
	};
};

export default connect(null, mapDispatchToProps)(Menu);
