import React, { Component } from 'react';
import FullCarousel from './FullCarousel';
import Headding from './Headding';
import Processes from './Processes';
import SiteRoute from './SiteRoute';

class Home extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className='landingPage'>
				<FullCarousel/>
				<Headding />
				<Processes />
				<SiteRoute />
			</div>
		);
	}
}

export default Home;