import React, { Component } from "react";
import { Col, Row, List } from "antd";

class Disclamer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const data = [
      "Foil Stamping",
      "Packaging Solutions",
      "Hardcover Books",
      "Letterpress",
      "Custom Boxes",
      "Napkins",
      "Offset Printing",
      "Die Cutting",
      "Embossing",
      "Digital Printing",
      "Foil Edging",
      "Fulfillment",
      "Painted Edging",
      "Variable Data Printing",
      "White Ink Mailing",
    ];

    return (
      <div>
        <Row type="flex" justify="center" align="middle" className="disclamer">
          <Col xs={24} sm={24} md={12} lg={11} xl={11}>
            <img src="/images/about.jpg" alt="" />
          </Col>
          <Col xs={24} sm={24} md={12} lg={11} xl={11}>
            <h1>PAPERLEAF PRESS</h1>
            <p>
              Paperleaf Press is a full-service custom print house located in
              the heart of downtown Los Angeles. With over thirty years
              experience in the luxury printing business, Paperleaf Press has
              the unique capacity to provide thoughtful solutions to your large
              format and complex printing needs, while also delivering the
              meticulous, customized service you desire. From the personalized
              printing of milestone invitations including weddings and social
              celebrations, to the sophisticated execution of corporate and
              business communication materials such as business cards,
              stationery and brochures, Paperleaf Press is your trusted source
              for designer-quality printing.
            </p>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="specialties"
        >
          <Col span={24}>
            <div className="title">Specialized Printing Services</div>
          </Col>
          <Col span={12} className="specialList">
            <List
              grid={{ sm: 2, md: 3, xl: 3 }}
              dataSource={data}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Disclamer;
