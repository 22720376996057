import React from 'react';
import PropTypes from 'prop-types';

class Banner extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {

		return (
			<div className="layoutBanner">
				<h1 className="title">{this.props.title}</h1>
				<div className="overlayBlack"></div>
				<div className="overlayTeal"></div>
				<img src={this.props.img} />
			</div>
		);
	}
}

Banner.propTypes = {
	img: PropTypes.string,
	title: PropTypes.string
};

export default Banner;
