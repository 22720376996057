import React from 'react';
import {
	Col
} from 'antd';

class Address extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Col span={24}>
				<Col span={24}>
					<h1>Paperleaf Press</h1>
				</Col>
				<Col span={24}>
					108 N San Fernando Rd
				</Col>
				<Col span={24}>
					Los Angeles, CA 90031
				</Col>
				<Col span={24}>
					(323) 225-2230
				</Col>
				<Col span={24}>
					info@thepaperleafpress.com
				</Col>
				<Col span={24}>
					Mon to Fri 9am - 5pm
				</Col>
			</Col>
		);
	}
}

export default Address;
