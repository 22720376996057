/* eslint-disable import/default */

import React from 'react';
import { render } from 'react-dom';
import { browserHistory } from 'react-router';
import configureStore from './store/configureStore';
require('./favicon.ico'); // Tell webpack to load favicon.ico
import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import { syncHistoryWithStore } from 'react-router-redux';
import ReactGA from 'react-ga';
import axios from 'axios';
import App from './App';

axios.defaults.baseURL = "http://paperleaf-api.us-east-2.elasticbeanstalk.com/api/";

ReactGA.initialize("UA-140508671-1");

// store
const store = configureStore();

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store.store);

history.listen((location) => {
	ReactGA.set({ page: location.pathname }); // Update the user's current page
	ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

render(
	<App store={store.store} persistor={store.persistor} history={history} />
	, document.getElementById('app'));

