module.exports = {
	portfolio: [
		{
			url: "foil1.jpg",
			type: "foil"
		},
		{
			url: "foil2.jpg",
			type: "foil"
		},
		{
			url: "foil3.jpg",
			type: "foil"
		},
		{
			url: "foil4.jpg",
			type: "foil"
		},
		{
			url: "foil5.jpg",
			type: "foil"
		},
		{
			url: "foil6.jpg",
			type: "foil"
		},
		{
			url: "foil8.jpg",
			type: "foil"
		},
		{
			url: "foil10.jpg",
			type: "foil"
		},
		{
			url: "foil11.jpg",
			type: "foil"
		},
		{
			url: "foil12.jpg",
			type: "foil"
		},
		{
			url: "foil13.jpg",
			type: "foil"
		},
		{
			url: "foil14.jpg",
			type: "foil"
		},
		{
			url: "foil15.jpg",
			type: "foil"
		},
		{
			url: "foil16.jpg",
			type: "foil"
		},
		{
			url: "foil19.jpg",
			type: "foil"
		},
		{
			url: "foil20.jpg",
			type: "foil"
		},
		{
			url: "foil21.jpg",
			type: "foil"
		},
		{
			url: "foil22.jpg",
			type: "foil"
		},
		{
			url: "foil23.jpg",
			type: "foil"
		},
		{
			url: "foil24.jpg",
			type: "foil"
		},
		{
			url: "foil25.jpg",
			type: "foil"
		},
		{
			url: "foil26.jpg",
			type: "foil"
		},
		{
			url: "foil27.jpg",
			type: "foil"
		},
		{
			url: "foil28.jpg",
			type: "foil"
		},
		{
			url: "foil29.jpg",
			type: "foil"
		},
		{
			url: "foil30.jpg",
			type: "foil"
		},
		{
			url: "foil31.jpg",
			type: "foil"
		},
		{
			url: "foil32.jpg",
			type: "foil"
		},
		{
			url: "letterpress1.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress3.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress4.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress6.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress7.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress8.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress9.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress10.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress11.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress12.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress13.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress14.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress15.jpeg",
			type: "letterpress"
		},
		{
			url: "letterpress16.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress17.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress18.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress19.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress20.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress21.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress22.jpg",
			type: "letterpress"
		},
		{
			url: "letterpress23.jpg",
			type: "letterpress"
		},
		{
			url: "offset1.jpg",
			type: "offset"
		},
		{
			url: "offset2.jpg",
			type: "offset"
		},
		{
			url: "offset4.jpg",
			type: "offset"
		},
		{
			url: "offset5.jpg",
			type: "offset"
		},
		{
			url: "offset6.jpg",
			type: "offset"
		},
		{
			url: "offset7.jpg",
			type: "offset"
		},
		{
			url: "offset8.jpg",
			type: "offset"
		},
		{
			url: "offset9.jpg",
			type: "offset"
		},
		{
			url: "offset12.jpg",
			type: "offset"
		},
		{
			url: "offset13.jpg",
			type: "offset"
		},
		{
			url: "offset14.jpg",
			type: "offset"
		},
		{
			url: "offset15.jpg",
			type: "offset"
		},
		{
			url: "offset16.jpg",
			type: "offset"
		},
		{
			url: "offset17.jpg",
			type: "offset"
		},
		{
			url: "offset18.jpg",
			type: "offset"
		},
		{
			url: "offset21.jpg",
			type: "offset"
		},
		{
			url: "offset22.jpg",
			type: "offset"
		},
		{
			url: "specialty1.jpg",
			type: "specialty"
		},
		{
			url: "specialty2.jpg",
			type: "specialty"
		},
		{
			url: "specialty3.jpg",
			type: "specialty"
		},
		{
			url: "specialty4.jpg",
			type: "specialty"
		},
		{
			url: "specialty5.jpg",
			type: "specialty"
		},
		{
			url: "specialty6.jpg",
			type: "specialty"
		},
		{
			url: "specialty7.jpg",
			type: "specialty"
		},
		{
			url: "specialty8.jpg",
			type: "specialty"
		},
		{
			url: "specialty9.jpg",
			type: "specialty"
		},
		{
			url: "specialty10.jpg",
			type: "specialty"
		},
		{
			url: "specialty11.jpg",
			type: "specialty"
		},
		{
			url: "specialty12.jpg",
			type: "specialty"
		},
		{
			url: "specialty13.jpg",
			type: "specialty"
		},
		{
			url: "specialty15.jpg",
			type: "specialty"
		},
		{
			url: "specialty16.jpg",
			type: "specialty"
		},
		{
			url: "specialty17.jpg",
			type: "specialty"
		},
		{
			url: "specialty18.jpg",
			type: "specialty"
		},
		{
			url: "specialty19.jpg",
			type: "specialty"
		},
		{
			url: "specialty20.jpg",
			type: "specialty"
		},
		{
			url: "specialty21.jpg",
			type: "specialty"
		},
		{
			url: "specialty22.jpg",
			type: "specialty"
		},
		{
			url: "specialty23.jpg",
			type: "specialty"
		},
		{
			url: "specialty24.jpg",
			type: "specialty"
		},
		{
			url: "specialty25.jpg",
			type: "specialty"
		},
		{
			url: "specialty26.jpg",
			type: "specialty"
		},
		{
			url: "specialty27.jpg",
			type: "specialty"
		},
		{
			url: "specialty28.jpg",
			type: "specialty"
		},
		{
			url: "specialty29.jpg",
			type: "specialty"
		},
		{
			url: "specialty30.jpg",
			type: "specialty"
		}
	]
}

