import React, { Component } from 'react';

class NotFound extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div>
				Not Found Page
			</div>
			);
	}
}

export default NotFound;