import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {push} from 'react-router-redux';
import {Row, Col, Button} from 'antd';

class SiteRoute extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const {goToPage} = this.props;
		return (
			<Row type='flex' justify='space-around'  align="middle" className='siteRoute'>
				<Col xs={24} sm={24} md={6} lg={6} xl={4}>
					<Button size='large' type="primary" onClick={() => goToPage('about')}>About</Button>
				</Col>
				<Col xs={24} sm={24} md={6} lg={6} xl={4}>
					<Button size='large' type="primary" onClick={() => goToPage('portfolio')}>Portfolio</Button>
				</Col>
				<Col xs={24} sm={24} md={6} lg={6} xl={4}>
					<Button size='large' type="primary" onClick={() => goToPage('contact')}>Contact</Button>
				</Col>
			</Row>
		);
	}
}

SiteRoute.propTypes = {
	goToPage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToPage: (page) => dispatch(push(page))
	};
};

export default connect(null, mapDispatchToProps)(SiteRoute);
