import React from 'react';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from "react-google-maps";

class Map extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {

		const MapWithAMarker = withScriptjs(withGoogleMap(() =>
			<GoogleMap
				defaultZoom={21}
				defaultCenter={{ lng: -118.2210404, lat: 34.0746233 }}
			>
				<Marker
					position={{ lng: -118.2210404, lat: 34.0746233 }}
				/>
			</GoogleMap>
		));

		return (
			<MapWithAMarker
				googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCYyaiOK9Ttrditur7FlEmQmQbhhe5SZx8&v=3.exp"
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div id="mapContainer" style={{ height: `443px` }}/>}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		);
	}
}

export default Map;
