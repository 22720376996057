import React, { Component } from 'react';
import {Row, Col} from 'antd';

class Headding extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<Row id="headding" className='headding' type='flex' justify='space-around'  align="middle" >
				<Col span={24}>
					<h1>Printing and Custom Services!</h1>
				</Col>
			</Row>
		);
	}
}

export default Headding;